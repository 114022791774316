import * as React from "react";
import { Button } from "../element/Button";
import "./TheCure.scss";

export const TheCure = () => {
  return (
    <div className="cure">
      <h2>The Cure</h2>
      <div className="container">
        <div className="card card--blue">
          <div className="content--helper">
            <div className="card__header">
              <div className="bubble">
                <h3>1</h3>
              </div>
            </div>
            <div className="card__content">
              <h4>Ensure Ontarians have a family doctor working alongside a team, so patients can get the help they need faster.</h4>
              <p>Research shows that patients with access to comprehensive team-based primary care, led by a family doctor, have better health outcomes, fewer visits to the hospital/emergency department/specialty care, and overall are more satisfied with their care.</p>
              <p>The Ontario government needs to commit to ensuring every family doctor and their patients have access to a team. Here’s how the Ontario government can help.</p>
              {/* <p>When Ontario’s families have equitable access to team-based care, it gives them comprehensive care and access to a team of healthcare providers who know them, their loved ones, and their history.  </p> */}
              {/* <p>We need to increase access to team-based care. Here’s how the Ontario government can help.</p>   */}
            </div>
            <div className="card__button">
              <Button text="Policy Proposals" flag={[' button__call-to-action ', ' button--tabletFullWidth']} isNav={true} handleClick={"policy.pdf"} isNewPage={true} />
            </div>
          </div>
        </div>
        <div className="card card--fuchsia">
          <div className="content--helper">
            <div className="card__header">
              <div className="bubble">
                <h3>2</h3>
              </div>
            </div>
            <div className="card__content">
              <h4>Improve the accessibility of care by increasing the time that family doctors can spend providing direct patient care.</h4>
              <p>Family doctors say they can spend up to 25% of their week on administrative work. This is time that could be spent providing direct patient care.  </p>
              <p>We need to give family doctors more time to spend on direct patient care. Here’s how the Ontario government can help.  </p>  
            </div>
            <div className="card__button">
              <Button text="Policy Proposals" flag={[' button__call-to-action ', ' button--tabletFullWidth']} isNav={true} handleClick={"policy.pdf"} isNewPage={true}  />
            </div>
          </div>
        </div>
        <div className="card card--viking">
          <div className="content--helper">
            <div className="card__header">
              <div className="bubble">
                <h3>3</h3>
              </div>
            </div>
          
            <div className="card__content">
              <h4>Ensure every Ontarian has a family doctor by recruiting and retaining more family doctors within the province.</h4>
              <p>1.3 million Ontarians live without a doctor. And 1-in-5 family physicians plan on retiring within the next five years.  </p>
              <p>We need to recruit and retain more family physicians. Here’s how the Ontario government can help.  </p>
            </div>
            <div className="card__button">
              <Button text="Policy Proposals" flag={[' button__call-to-action ', ' button--tabletFullWidth']} isNav={true} handleClick={"policy.pdf"} isNewPage={true}  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};