import * as React from "react";
import playIcon from "../../images/icons/play--filled--alt.svg";

type props = {
  img: any;
  name: string;
  toggleModal: Function;
}

export const StoriesVideo = ({img, name, toggleModal} : props) => {

  return (
    <button className="video__tile" onClick={() => toggleModal()}>
      <div className="thumbnail">
        <div className="hover hover__cover"/>
        <div className="hover hover__text">
          <span>Watch Story</span>
        </div>
        
        <img src={img}/>
      </div>
      <div className="description">
        <div className="description__playIcon">
          <img src={playIcon}/>
        </div>
        <h5>{name}</h5>
      </div>
    </button>
  );
}