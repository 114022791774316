import * as React from 'react';
import image from "../../images/home/Baby-Image@2x.png";
import "./WhoWeAre.scss";

export const WhoWeAre = () => {
  return (
    <div className='whoContainer'>
      <div className='whoContainer__image'>
        <img src={image} />
      </div>
      <div className='whoContainer__content'>
        <h2>Who we are</h2>
        <p>The Ontario College of Family Physicians represents more than 15,000 family physicians across the province.  </p>
        <p>We provide our members evidence-based education and professional development. We promote and recognize leadership in family medicine and advocate for the vital role family physicians play in delivering high quality of care to Ontario’s patients and families.  </p>
        <p>The Ontario College of Family Physicians was established in 1954 and is a not-for-profit and voluntary organization. It is the provincial chapter of the College of Family Physicians of Canada. </p>
      </div>
    </div>
  );
};