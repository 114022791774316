import * as React from "react";
import images from "../../images/home/CriticalGallery@2x.png";
import "./CriticalDoctors.scss";

export const CriticalDoctors = () => {
  return (
    <div className="critical-doctors">
      <div className="collage">
        <img src={images} />
        {/* <div className="collage__column">
          <div className="collage__item--large">
            <img />
          </div>
          <div className="collage__item--small">
            <img />
          </div>
        </div> */}
        {/* <div className="collage__column">
          <div className="collage__item--small">
            <img />
          </div>
          <div className="collage__item--large">
            <img />
          </div>
        </div> */}
      </div>
      <div className="copy">
        <h2>Family doctors are critical in keeping Ontario’s families, patients, and communities healthy.  </h2>
        <p>In many small towns, family doctors are the backbone: They run their own local practices, work in nearby hospitals and emergency rooms, and tend to those in long-term care homes. </p>
        {/* <p>Having a family doctor is associated with decreased hospitalization, higher patient satisfaction, and greater equity in health outcomes.</p> */}
        {/* <p>This pandemic has created a backlog that is preventing Ontario’s family doctors from caring for their patients and is hindering Ontarians from getting the care they deserve. </p> */}
        <p>Having a regular family doctor improves health outcomes, reduces mortality, decreases hospitalizations, and reduces healthcare costs.</p>
        <p>This pandemic has created a backlog that is hindering Ontarians from getting the timely care they deserve.</p>
        <p>Ontario’s healthcare system was already feeling the impact of a family doctor shortage. This problem will become a crisis as more doctors retire and fewer medical school graduates enter this profession. </p>
        <p>This is an emergency, but doctors are used to treating those. We have cures. </p>
      </div>
    </div>
  );
};