import * as React from "react";
import { Button } from "../element/Button";
import hero from "../../images/home/Hero-Image@2x.png";
import heroMobile from "../../images/home/combinedfaces.png"
import mobileMillionBG from "../../images/home/million-mobile-BG.png";
import mobileMillionText from "../../images/home/million-text.png";
import million from "../../images/home/Group-5@3x.png";
import divider from "../../images/Divider/Divider-Desktop@3x.png";
import mobileDivider from "../../images/Divider/Divider-Mobile@3x.png";
import "./Hero.scss";

export const Hero = () => {
  return (
    <section className="hero">
      <div className="million">
        {/* <img className="million__img" src={million}/>
        <div className="million__img million__img--mobile">
          <img className="million__BG--mobile" src={mobileMillionBG} />
          <img className="million__text--mobile" src={mobileMillionText} />
        </div> */}
        <div className="hero__copy">
        <h3>Ontarians live without a family doctor.</h3>
        <div className="details">
          <p> If current trends persist, more than 4 million Ontarians could be without a family doctor by 2026. The family doctor shortage will be felt by all, regardless of where we live in Ontario. In some cases, entire families will be without one. </p>
          <p>Every Ontarian needs a family doctor. The Ontario College of Family Physicians (OCFP) is ready to work with the Ontario government to make this happen. </p>
          <Button text="Find out how" flag={[' button__call-to-action ', ' button--tabletFullWidth ']} handleClick='#critical' isNav={true} />
        </div>
      </div>
      </div>
      <div className="hero__image">
        <img src={hero} />
        {/* <img className="mobile" src={heroMobile} /> */}
      </div>
      <img className="hero__divider" src={divider}/>
      <img className="hero__divider hero__divider--mobile" src={mobileDivider}/>
    </section>
  );
};
