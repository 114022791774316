import * as React from "react";
import "./Button.scss";

type props = {
  text: string;
  flag: string[];
  handleClick: Function | string;
  isNav: boolean;
  isNewPage: boolean;
}

export const Button = ({text, flag, handleClick='', isNav=false, isNewPage=false}: props) => {
  return (
    <>
      {isNav ? (
        <a className={`button ${flag}`} href={`/${handleClick}`} target={isNewPage ? "_blank" : ""} rel="noreferror">
          {text}
        </a>
      ) : (
        <button className={`button ${flag}`} onClick={() => handleClick}>
          {text}
        </button>
      )}

    </>
  );
};