import * as React from "react";
import closeIcon from "../../images/icons/close.svg";

type props ={
  embedId: string;
  toggleModal: Function;
}
export const StoriesModal = ({embedId, toggleModal}: props) => { 
  
  return(
    <div className="StoriesModal__container" onClick={() => toggleModal()}>
      <img className="close" src={closeIcon} />
      <div className="StoriesModal__card">
        <div className="StoriesModal__player">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}?rel=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        </div>
      </div>
    </div>
  );
}

