import * as React from "react";
import images from "../../images/home/CriticalGallery@2x.png";
import "./CriticalDoctors.scss";
import { StoriesVideo } from "./StoriesVideo";
import { StoriesModal } from "./StoriesModal";
import topDivider from "../../images/Divider/Divider-Desktop-rotated.png";
import divider from "../../images/Divider/Divider-Desktop.png";
import "./Stories.scss";
import Annette from "../../images/video/Annette.png";
import Ballo from "../../images/video/Ballo.png";
import Gord from "../../images/video/Gord.png";
import Janine from "../../images/video/Janine.png";
import KateMiller from "../../images/video/Kate-Miller.png";
import SohalGoyal from "../../images/video/Sohal-Goyal.png";



export const Stories = () => {
  const [embedId, setEmbedId] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const toggleModal = (id:string) => {
    setEmbedId(id);
    setOpenModal(!openModal);
  }

  return (
    <div className="stories-container hidden">
      <img className="divider divider-top" src={topDivider}/>
      <div className="stories">
        <div className="copy">
          <h2>Our Stories</h2>
          <p>Whether you are living life with or without a doctor,</p>
          <p>we can all relate to these stories</p>
        </div>
        <div className="video">
          <StoriesVideo img={KateMiller} name={'Dr. Kate Miller'} toggleModal={() => toggleModal("KtkQazZbO3A")}/>
          <StoriesVideo img={SohalGoyal} name={'Dr. Sohal Goyal'} toggleModal={() => toggleModal("Y1VBHt3Di5I")}/>
          <StoriesVideo img={Annette} name={'Annette'} toggleModal={() => toggleModal("MwjSDAkUu80")}/>
        </div>
        <div className="video">
          <StoriesVideo img={Janine} name={'Janine'} toggleModal={() => toggleModal("aZJQjlWhytc")}/>
          <StoriesVideo img={Gord} name={'Gord'} toggleModal={() => toggleModal("MYFV-O7v6Eo")}/>
          <StoriesVideo img={Ballo} name={'Ballo'} toggleModal={() => toggleModal("JBpMVRoaz8Y")}/>
      
        </div>
        {openModal &&
          <StoriesModal embedId={embedId} toggleModal={() => toggleModal("")}/>
        }
      </div>
      <img className="divider divider-bottom" src={divider}/>
    </div>
  );
};
