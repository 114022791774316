import * as React from 'react';
import queryString from 'query-string';
import Url from "url-parse";
import hashtag from "../../images/home/OCFP_LWAD_Hashtag_02-26.svg";
// import hashtag from "../../images/home/OCFP_Hashtag_BG@2x.png";
import icon from "../../images/icon-inverse.svg";
import twitter from "../../images/home/Twitter-White.svg"
import facebook from "../../images/home/Facebook-White.svg"
import download from "../../images/home/downloadIcon.png";
import share1 from "../../images/home/OCFP_WebGraphic_01_V02.png";
import share2 from "../../images/home/OCFP_WebGraphic_02.png";
import "./Solution.scss";

export const Solution = () => {

  const image1 = new Url("https://lifewithoutadoctor.ca/OCFP_WebGraphic_01_V02.png");
  const image2 = new Url("https://lifewithoutadoctor.ca/OCFP_WebGraphic_02.png");
  const qs1 = queryString.stringify(
    {
      url: "pic.twitter.com/9icHMJAj6N",
      text: "2.2 million Ontarians are living #LifeWithoutADoctor. I support the @OntarioCollege of Family Physicians and their plan to change this and help ensure every Ontarian has access to a family doctor. Learn more at www.LifeWithoutADoctor.ca",
      hashtags: "onpoli",
    }
  );
  const qs2 = queryString.stringify(
    {
      url: "pic.twitter.com/MlD7j1K4ip",
      text: "2.2 million Ontarians are living #LifeWithoutADoctor. I support the @OntarioCollege of Family Physicians and their plan to change this and help ensure every Ontarian has access to a family doctor. Learn more at www.LifeWithoutADoctor.ca",
      hashtags: "onpoli",
    }
  );  
  const FBqs1 = queryString.stringify(
    {
      u: image1.href,
      // text: "1.3 million Ontarians are living #LifeWithoutADoctor. I support the @OntarioCollege of Family Physicians and their plan to change this and help ensure every Ontarian has access to a family doctor. Learn more at www.LifeWithoutADoctor.ca #onpoli",
    }
  );
  const FBqs2 = queryString.stringify(
    {
      u: image2.href,
      // text: "1.3 million Ontarians are living #LifeWithoutADoctor. I support the @OntarioCollege of Family Physicians and their plan to change this and help ensure every Ontarian has access to a family doctor. Learn more at www.LifeWithoutADoctor.ca #onpoli",
    }
  );

  const shareUrl = (query: string) => {
    return `http://twitter.com/share?${query}`
  }
  const shareUrlFB = (query: string) => {
    return `https://www.facebook.com/sharer/sharer.php?${query}`
  }

  return (
    <div className='solution'>
      <div className='solution__tile'>
        <h2>Be part of the solution. <br/> Spread the word.</h2>
        <p>Help us ensure the government knows every Ontarian deserves a life with a family doctor. Share our campaign on social media.</p>
        <div className='hashtag'>
          {/* <div className='hashtag__block'>
            {'#'}
          </div>
          <span className='hashtag__text'>LifeWithoutADoctor</span> */}
          <img src={hashtag}/>
        </div>
      </div>
      <div className='solution__tile solution__tile--tiles'>
        <div className='actions'>
          <div className='actions__download'>
            <div className='card actions__download__card'>
              <div className='actions__content'>
                <img src={icon} />
                <h4>TOOLKIT</h4>
              </div>
              <button className='actions__button' onClick={ () =>
                window.open('/OCFP_Toolkit.zip')
              }>
                <p>Download Toolkit</p>
                <img src={download} />
              </button>
            </div>
          </div>
          <div className='actions__socials'>
            <div className='card actions__socials__card actions__socials__card--fuchsia share-card'>
              <div className='actions__content'>
                {/* <p>Lorem ipsum dolor sit amet, consectetur <strong>adipiscing elit sed do eiusmod tempor</strong> incididunt ut labore et dolore magna aliqua.</p>
                <p className='hash'>#LifeWithoutADoctor</p> */}
                <img src={share1} className="share-image" />
              </div>
              <button className='actions__button share-button'>
                <p>Share</p>
                <span>
                  <a href={shareUrl(qs1)} target='_blank' rel="noreferror">
                    <img src={twitter} />
                  </a>
                  <a href={shareUrlFB(FBqs1)} target='_blank' rel="noreferror">
                    <img src={facebook} />
                  </a>
                </span>
              </button>
            </div>
            <div className='card actions__socials__card actions__socials__card--viking share-card'>
              <div className='actions__content'>
                {/* <p>Lorem ipsum dolor sit amet, consectetur <strong>adipiscing elit sed do eiusmod tempor</strong> incididunt ut labore et dolore magna aliqua.</p>
                <p className='hash'>#LifeWithoutADoctor</p> */}
                <img src={share2} className="share-image" />
              </div>
              <button className='actions__button share-button'>
                <p>Share</p>
                <span>
                  <a href={shareUrl(qs2)} target='_blank' rel="noreferror">
                    <img src={twitter} />
                  </a>
                  <a href={shareUrlFB(FBqs2)} target='_blank' rel="noreferror">
                    <img src={facebook} />
                  </a>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};