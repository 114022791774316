import * as React from "react";
import { Section } from "../components/element/Section";
import { CriticalDoctors } from "../components/home/CriticalDoctors";
import { Hero } from "../components/home/Hero";
import { Solution } from "../components/home/Solution";
import { TheCure } from "../components/home/TheCure";
import { Stories } from "../components/home/Stories";
import { WhoWeAre } from "../components/home/WhoWeAre";


const IndexPage = () => {
  return (
    <>
      <Hero />
      <Section id={"critical"} flag={'--critical'} lastSection={false}>
        <CriticalDoctors />
      </Section>
      <Section flag={'--theCure'} lastSection={false}>
        <TheCure />
      </Section>
      <Stories />
      <Section id={"solution"} flag={''} lastSection={true}>
        <Solution />
      </Section>
      <WhoWeAre />
    </>
  )
}

export default IndexPage
